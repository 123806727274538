import React from "react";
import style from "./Footer.module.scss";

const Footer = () => {
  const date = new Date();
  return (
    <div className={style.footerWrapper}>
      <div className={style.footer}>
        <p>
          Разработка:&nbsp;
          <a href="https://mithra.ru">Mithra</a>&nbsp;{date.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
