import { Navigate } from "react-router-dom";
import Preloader from "../Preloader/Preloader";

const GuestGuard = ({ isAuthenticated, children }) => {
  // if (loading) {
  //   return <Preloader />;
  // }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};

export default GuestGuard;
