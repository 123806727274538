import { useContext, useEffect, Suspense, lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import style from "./App.module.scss";
import Preloader from "./components/Preloader/Preloader";
import Header from "./components/Header/Header";
// import Homepage from "./pages/Homepage/Homepage";
import Footer from "./components/Footer/Footer";
// import PostsPage from "./pages/PostsPage/PostsPage";
// import SinglePostPage from "./pages/SinglePost/SinglePostPage";
// import WritePost from "./pages/WritePost/WritePost";
// import SettingsPage from "./pages/SettingsPage/SettingsPage";
// import LoginPage from "./pages/LoginPage/LoginPage";
// import RegisterPage from "./pages/RegisterPage/RegisterPage";
import { Context } from "./context/Context";
// import Page404 from "./pages/Page404/Page404";
// import CookiesInfo from "./pages/CookiesInfo/CookiesInfo";
import GuestGuard from "./components/GuestGuard/GuestGuard";
import { NotificationContainer } from "react-notifications";
import useAuth from "./assets/hooks/useAuth";
import WebFont from "webfontloader";
import CookiesDisclaimer from "./components/CookiesDisclaimer/CookiesDisclaimer";

const Homepage = lazy(() => {
  return Promise.all([
    import("./pages/Homepage/Homepage"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});

const PostsPage = lazy(() => {
  return Promise.all([
    import("./pages/PostsPage/PostsPage"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const SinglePostPage = lazy(() => {
  return Promise.all([
    import("./pages/SinglePost/SinglePostPage"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const WritePost = lazy(() => {
  return Promise.all([
    import("./pages/WritePost/WritePost"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const SettingsPage = lazy(() => {
  return Promise.all([
    import("./pages/SettingsPage/SettingsPage"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const LoginPage = lazy(() => {
  return Promise.all([
    import("./pages/LoginPage/LoginPage"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const RegisterPage = lazy(() => {
  return Promise.all([
    import("./pages/RegisterPage/RegisterPage"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Page404 = lazy(() => {
  return Promise.all([
    import("./pages/Page404/Page404"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const CookiesInfo = lazy(() => {
  return Promise.all([
    import("./pages/CookiesInfo/CookiesInfo"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});

function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

function App() {
  useScrollToTop();
  const { isAuthenticated } = useAuth();
  const { loading } = useContext(Context);

  useEffect(() => {
    WebFont.load({
      custom: {
        families: [
          "GramatikaBlack",
          "GramatikaBlackItalic",
          "GramatikaBold",
          "GramatikaBoldItalic",
          "GramatikaItalic",
          "GramatikaLight",
          "GramatikaLightItalic",
          "GramatikaMedium",
          "GramatikaMediumItalic",
          "GramatikaRegular",
        ],
      },
    });
  }, []);

  return (
    <>
      <div className={style.appWrapper}>
        <Suspense fallback={<Preloader />}>
          <div className={style.app}>
            <header className={style.header}>
              <Header />
            </header>
            <div className={style.mainContent}>
              <Routes>
                <Route index path="/" element={<Homepage />} />

                <Route path="news">
                  <Route index element={<PostsPage />} />
                  <Route path=":postId" element={<SinglePostPage />} />
                  <Route
                    path="new"
                    element={
                      <GuestGuard isAuthenticated={isAuthenticated}>
                        <WritePost />
                      </GuestGuard>
                    }
                  />
                </Route>

                <Route
                  path="settings"
                  element={
                    <GuestGuard isAuthenticated={isAuthenticated}>
                      <SettingsPage />
                    </GuestGuard>
                  }
                />
                <Route path="cookies" element={<CookiesInfo />} />
                <Route path="login" element={<LoginPage />} />

                {/* <Route path="register" element={<RegisterPage />} /> */}

                <Route path="*" element={<Page404 />} />
              </Routes>
            </div>
            <footer className={style.footer}>
              <Footer />
            </footer>
          </div>
        </Suspense>
      </div>
      <CookiesDisclaimer />
      <NotificationContainer />
    </>
  );
}

export default App;
