import { createContext, useReducer, useEffect, useState } from "react";
import Reducer from "./Reducer";
const axios = require("axios");

const INITIAL_STATE = {
  user: null,
  isAuthenticated: false,
  isFetching: false,
  error: false,
};

export const Context = createContext({
  ...INITIAL_STATE,
});

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

  const [loading, setLoading] = useState(true);

  const getUserInfo = async () => {
    const token =
      localStorage.getItem("token") !== undefined
        ? localStorage.getItem("token")
        : null;

    if (token) {
      try {
        axios.defaults.headers.common["x-auth-token"] = token;
        // ***DEV/PROD***
        let res;
        if (process.env.REACT_APP_MODE === "DEV") {
          res = await axios.get("/auth/info");
        } else if (process.env.REACT_APP_MODE === "PROD") {
          res = await axios.get("/api/auth/info");
        }
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: {
            ...res.data.user,
          },
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      delete axios.defaults.headers.common["x-auth-token"];
    }
  };

  useEffect(() => {
    const checkUser = async () => {
      if (!state.user) {
        setLoading(true);
        await getUserInfo();
        setLoading(false);
      }
    };
    checkUser();
  }, [state]);

  return (
    <Context.Provider
      value={{
        ...state,
        getUserInfo,
        dispatch,
        loading,
      }}
    >
      {children}
    </Context.Provider>
  );
};
