import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LogoSVG } from "../../assets/imgs/newmetroLogo.svg";
import { ReactComponent as ReturnSVG } from "../../assets/imgs/return.svg";
import { ReactComponent as WriteSVG } from "../../assets/imgs/write.svg";
import { ReactComponent as SettingsSVG } from "../../assets/imgs/settings.svg";
import { ReactComponent as LogoutSVG } from "../../assets/imgs/logout.svg";
import style from "./Header.module.scss";
import { Context } from "../../context/Context";
import useAuth from "../../assets/hooks/useAuth";

const Header = (props) => {
  // ***DEV/PROD***
  let PF;
  if (process.env.REACT_APP_MODE === "DEV") {
    PF = `${process.env.REACT_APP_IMAGES_PF}`;
  } else if (process.env.REACT_APP_MODE === "PROD") {
    PF = "/imgs/";
  }

  const { user, dispatch } = useContext(Context);
  const { isAuthenticated } = useAuth();

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch({ type: "LOGOUT" });
    window.location.replace("/login");
  };

  return (
    <>
      <div className={style.headerWrapper}>
        <div className={style.header}>
          <div className={style.left}>
            <Link to="/">
              <div className={style.logo}>
                <LogoSVG />
              </div>
              <div className={style.title}>
                <div className={style.line1}>
                  <span>МЕТРО-НН</span>
                </div>
                <div className={style.line2}>
                  <span>Наше. Новое.</span>
                </div>
              </div>
            </Link>
          </div>
          <div className={style.rightWrapper}>
            <div className={style.right}>
              {!isAuthenticated && (
                <a href="http://metronn.ru" className={style.toMain}>
                  <span>к основному сайту</span>
                  <ReturnSVG />
                </a>
              )}

              {isAuthenticated ? (
                <>
                  <Link to="/news/new">
                    <div className={style.addPost}>
                      <WriteSVG />
                      <span>Добавить новость</span>
                    </div>
                  </Link>

                  <div className={style.user}>
                    <div className={style.profile}>
                      <div
                        className={style.avatarImg}
                        style={{
                          background: `url(${PF + user.profilePic}) no-repeat`,
                        }}
                      />
                      <span>{user.username}</span>
                    </div>

                    <span className={style.settings}>
                      <Link to="settings">
                        <SettingsSVG />
                      </Link>
                    </span>

                    <button onClick={handleLogout} className={style.logout}>
                      <LogoutSVG />
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
