import React from "react";
import { ReactComponent as LogoSVG } from "../../assets/imgs/newmetroLogoMini.svg";
import style from "./Preloader.module.scss";

const Preloader = () => {
  return (
    <>
      <div className={style.logo}>
        <LogoSVG />
      </div>
    </>
  );
};

export default Preloader;
